<template>

  <div class="genreBoard">
      <div 
          v-on:click="chooseLanguage(item)"
          v-for="item in passed_mainmenu_languages" v-bind:key="item.id" v-bind:class="{'genreBtnActive': item.isActive}" class="genreBtn linkable">
          
              {{ item.contents }}
    </div>
      
  </div>

</template>


<script>
export default {
  
  props: ['passed_mainmenu_languages'],

  methods: {

    chooseLanguage: function(item) {

      this.$emit('clicked_language', item.contents);
      
    }
        
  }

}

</script>


<style scoped>

.genreBoard {

  box-sizing: border-box;

  text-align: center;
    
  display: flex;
  justify-content: space-around;
  
  padding-left: 30px;
  padding-right: 30px;

      
} 

.linkable {

  cursor: pointer;

}


.genreBtn {

  box-sizing: border-box;

  display:inline-flex;
  justify-content: center;

  align-items: center;

  width: 300px;
  height: 60px;

  margin-left: 10px;
  margin-right: 10px;
  
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;

  font-size: 22px;

  background-color: #f1f1f1; 
  
}

.genreBtnSleep {

  color:gray;
  opacity:0.6; 

}
.genreBtnActive {
  
  background-color: #bbb7b7;
  cursor: pointer;

}

</style>
