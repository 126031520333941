<template>
    <div class="modeBtn reading"
        v-on:click="callTochangeMode"
        v-if="passed_mode === true"
    > 읽기 테스트 <br> (외국어 -> 한국어) </div>

    <div class="modeBtn writing"
        v-on:click="callTochangeMode"
        v-else-if="passed_mode === false"
    > 쓰기 테스트 <br> (한국어 -> 외국어) </div>


</template>


<script>
export default { 
  
    props: ['passed_mode'],

    methods: {
        
    callTochangeMode:function() {

        this.$emit('clicked_mode');
        
        }

    }

}

</script>

<style>

.modeBtn {  


 
 background-color: rgb(172, 99, 147);

 width: 200px;
 height: 60px;

 padding-top:20px;

 text-align: center;

 border: solid 3px;
 cursor: pointer;

}

.reading {

background-color: aquamarine;


}

.writing {

    background-color:rgb(206, 107, 156);

}


</style>