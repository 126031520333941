<template>
    <div
          v-on:click="clicked_button(item)"
          v-for="item in twobuttons" v-bind:key="item.id" class="prepostBtn prepostBtnActive">
          {{ item.contents }}
    </div>
  </template>
  
  <script>
  export default {

    props:['twobuttons'],

    emits: ['clicked_buttons'],

    methods: {

      clicked_button: function(item) {

        this.$emit('clicked_buttons', item);
        console.log(item.contents, "버튼이 클릭되었습니다.");

      }

      /*
      press_right: function(right_item) {

//        var right_item = item[0];
        this.$emit('rightkey', right_item);
        //console.log(item.contents, "버튼이 클릭되었습니다.");

      }
      */


    }
    
  }
  </script>
  
  <style>
        .prepostBtn {

          user-select : none;

          box-sizing: border-box;

          display:inline-flex;
          justify-content: center;

          align-items: center;

          width: 500px;
          height: 80px;

          margin-left: 10px;
          margin-right: 10px;

          padding-top: 10px;
          padding-bottom: 10px;
          border-radius: 5px;

          font-size: 30px;
      
      }

      .prepostBtnActive {
  
      background-color: #f1f1f1; 

      }
  </style>