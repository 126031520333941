<template>

    <div class="wordbox" v-bind:class="[small_size? 'small' : 'normal']" >
        {{ now_card }}  </div>

    

</template>

<script>
export default {

    props: ['now_card', 'small_size']

    
}

</script>

<style scoped>

.wordbox {

    width: auto;   

    border: solid 2px;
    
    margin: 3px 3px 3px 3px;

    font-family: 'Noto Serif TC', serif;
    
}

.normal {

    background-color: rgb(165, 164, 164);
    white-space: nowrap;
    font-size: 140px;
    line-height: 500px;
    width: auto;
    text-align: center;
        
    text-size-adjust: auto;     

}

.small {

    background-color: rgb(165, 164, 164);
    white-space: nowrap;
    font-size: 100px;
    line-height: 500px;
    width: auto;
    text-align: center;
        
    text-size-adjust: auto;     

}

</style>
