<template>
    <div class="status_text"
      v-if="present_status.isFront==true"     
    > {{ present_status.current_turn+1 }}번째 카드 <span class="front_color">앞</span> / 총 {{ present_status.size_deck }} 장
    </div>
    <div class="status_text"
      v-else-if="present_status.isFront==false"     
    > {{ present_status.current_turn+1 }}번째 카드 <span class="back_color">뒤</span> / 총 {{ present_status.size_deck }} 장
    </div>
  </template>
  
  <script>
  export default {

    props:['present_status']
    
  }
  </script>
  
  <style>
  .front_color {

    color:red;
  }

  .back_color {

    color:orange;
  }
  
  .status_text {
    
    white-space: nowrap;
    font-size: 40px;  
    text-align: right;
    margin-right: 100px;
    margin-bottom: 10px;

     /* background-color: #5982aa; */

    }

  </style>