<template>
    <div>

      <div
          v-on:click="changeDivisionsContents(item)" 
          v-for="item in passed_second_divisions" v-bind:key="item.id" v-bind:class="{'secondDivisionBtnActive': item.isActive}" class="secondDivisionBtn">
               {{ item.contents }}

      </div>
    </div>
</template>
  
  <script>
  export default {

    props: ['passed_second_divisions'],

    methods: {

      changeDivisionsContents: function(item) {                                        
                                   
        this.$emit('clicked_seconddivision', item.id);
                                                
      }                                        
  }

}

  </script>
  
  <style>
      

      .secondDivisionBtn {

      box-sizing: border-box;

      display:inline-flex;
      justify-content: center;

      align-items: center;


      height: 40px;
      

      margin-top: 5px;
      margin-left: 5px;
      margin-right: 10px;

      padding-top: 5px;
      padding-bottom: 10px;
      padding-left:5px;
      padding-right:5px;

      border-radius: 5px;

      font-size: 16px;

      background-color: #f1f1f1; 
      cursor: pointer;

      }

      .secondDivisionBtnActive {

        background-color: #bbb7b7;
        cursor: pointer;

      }

  </style>