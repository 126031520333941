<template>
    <div>
                                                              광고판
    </div>
  </template>
  
  <script>
  export default {
    
  }
  </script>
  
  <style>
  /* 스타일 코드 */
  </style>