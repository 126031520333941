<template>
    <div class="logostyle">Timeless Flash</div> <span class="superscript"> beta </span>
  
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
    
  .logostyle {

    text-align: center;
    
    font-size: 32px;
    font-weight: 400;

    padding-top: 5px;

    font-family: 'Black Han Sans', sans-serif;

  }
  
   h2 { 
     
     font-size: 24px;
  
    
 
     font-weight: 400;
        
  }

  .superscript {

    font-size:12px;
    margin-left: -40px;
    padding-bottom: 20px;
    
  }
  
  </style>