<template>
  
    <!-- <div class="footer">-->
    <div class ="footer">
      <div class="footer_left"> copyleft forever </div>
      <div class="footer_right"> 유진언어연구소는 여러분의 성장을 기원합니다! </div>
    </div>
           
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
  
  .footer {
    
    display: flex;
    justify-content: space-between;
     
   }
  
  .footer_left {
    display:inline-block;
    
    justify-content: flex-start;
    padding-top: 12px;
    padding-left: 10px;

    width: 200px;
    font-size: 16px;

  }
    
  .footer_right {
    
    display:inline-block;
    
    justify-content: flex-end;

    text-align: right;

    padding-top: 12px;
    padding-right: 10px;

    width: 500px;
    font-size: 14px;

    /* font-family: 'Dongle', sans-serif; */
  
  }
  
  </style>