<template>

    <div class="genreBoard">
      <div 
           v-on:click="spreadLeveldivisions(item)"
           v-for="item in passed_first_divisions" v-bind:key="item.id" v-bind:class="{'firstDivisionBtnActive': item.isActive}" class="firstDivisionBtn">
               {{ item.contents }}
      </div>

    </div>
</template>
  
  <script>
  export default {

    props: ['passed_first_divisions'],

    methods: {

      spreadLeveldivisions: function(item) {

        this.$emit('clicked_firstdivision', item.contents);

      }

    }

  }
  </script>
  
  <style>
  .genreBoard {

    box-sizing: border-box;

    text-align: center;
    display: flex;
    justify-content: flex-start;

    
    padding-right: 50px;

   
} 

  .firstDivisionBtn {

    box-sizing: border-box;

    display:inline-flex;
    justify-content: center;

    align-items: center;

    height: 40px;

    margin-top:3px;
    margin-left: 10px;
    margin-right: 10px;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left:5px;
    padding-right:5px;

    border-radius: 5px;

    font-size: 16px;
    
    background-color: #c2bcbc;     
    cursor: pointer;
 
  }

  .firstDivisionBtnActive {
  
  background-color: #bbb7b7;
  cursor: pointer;

  }
  </style>