<template>
  <div>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  </div>
  
  <!-- 로고와 광고 -->
  <div class="header"> 
        <appLogo class="logo"></appLogo>
        <appMainGenre class="maingenre"
          v-bind:passed_mainmenu_languages="mainmenu_languages"
          v-on:clicked_language="changeOtherlanguage" 
        > </appMainGenre>  
    

        <appAD class="advertisement"></appAD>  </div>

  <!-- 메인메뉴-->
  <div class="neck">
      <div class="divisions">
        <div class="division1"> 
          <appDivision
            v-bind:passed_first_divisions="first_division"
            v-on:clicked_firstdivision="spreadOtherdivisions"
          > </appDivision> </div>

        <!-- 첫번째 구분선-->
        <div class="division2"> 
          <appLevels
            v-bind:passed_level_divisions="levels_division"
            v-on:clicked_leveldivision="selectLevel"
          > </appLevels> </div>

        <div class="division3"> 
          <appSubDivision
            v-bind:passed_second_divisions="second_division"
            v-on:clicked_seconddivision="selectContents"
          > </appSubDivision> </div>
      </div>

      <div class="mode">
        <appMode
        v-bind:passed_mode="mode_readwrite"
        v-on:clicked_mode="swapReadydeck"
                
        > </appMode> </div>

  </div>

  <div class="body">
        <div class="window"> <appWindow
            v-bind:now_card="current_wordcard"
            v-bind:small_size ="getSmall"
         > </appWindow> </div>

        <div class="status"> <appStatus
            v-bind:present_status="status_figures"
        > </appStatus> </div>

        <div class="button"> <appButton
            v-on:clicked_buttons="dealing_card"
            v-bind:twobuttons="buttons"
          > </appButton> </div>

  </div>

  <div class="footer"> <appFooter> </appFooter> </div>

</template>

<script>

import appLogo from './components/appLogo.vue'
import appAD from './components/appAD.vue'

import appMainGenre from './components/appMainGenre.vue' // 고정 메뉴

import appDivision from './components/appDivision.vue' // 메뉴 선택에 따라 바뀜 - 
import appLevels from './components/appLevels.vue'
import appSubDivision from './components/appSubDivision.vue'

import appMode from './components/appMode.vue'


import appWindow from './components/appWindow.vue'
import appButton from './components/appButton.vue'
import appStatus from './components/appStatus.vue'


import appFooter from './components/appFooter.vue'

// 1 floating button ( 읽기 -> 쓰기) 

//////////////////////////////////////// JSON ///////////////////////////////////////
import hangum_singleletter from "@/assets/cards/hangum_singleletter.json"   //한국 한자시험 낱자
import hangum_words from "@/assets/cards/hangum_words.json"   //한국 한자시험 단어
import hangum_homonym from "@/assets/cards/hangum_homonym.json"   //한국 한자시험 동음이의어
import hangum_idioms from "@/assets/cards/hangum_idioms.json"   //한국 한자시험 동음이의어
import hangum_radical from "@/assets/cards/hangum_radical.json"   //한국 한자시험 부수
import hangum_abbreviation from "@/assets/cards/hangum_abbreviation.json"   //한국 한자시험 약자

// import { reactive } from 'vue';

///////////// 일본어
import japanese_hiragana from "@/assets/cards/japanese_hiragana.json"   //일본어 기초 히라가나
import japanese_katakana from "@/assets/cards/japanese_katakana.json"   //일본어 기초 카타카나
import japanese_daily from "@/assets/cards/japanese_daily.json"   //일본어 기초 카타카나

//////////////////////////////////////// JSON ///////////////////////////////////////

export default {

  components: {
    'appLogo': appLogo,
    'appAD': appAD,
    'appMainGenre': appMainGenre,

    'appDivision': appDivision,
    'appLevels': appLevels,
    'appSubDivision': appSubDivision,

    'appMode': appMode,

    'appWindow': appWindow,
    'appStatus': appStatus,
    'appButton': appButton,
    'appFooter': appFooter
  },

  data: function() {

    return {

      test: [],

      current_language: " ",

      /******* 카드 덱에 관한 배열 ******/
      full_deck: [],       //현재 활성화된 모든 덱
      ready_deck: [],      // 현재 준비된 카드 덱
      current_wordcard: " ", // 화면에 뿌려지 위해 준비된 카드 한 면

      current_division: " ", // 현재 모드를 쌓는 변수 -> appStatus에 표기 될 것인 + 함수를 하나 만들어야 함

      INITIAL_LEVEL: 41,     // 한자 초기화할 때 레벨

      status_figures: [

          {id:0, size_deck:0},
          {id:1, current_turn:0},
          {id:2, isFront:true},

      ],
      
      mode_readwrite:true, // 한국어 -> 외국어 전환버튼

      getSmall: false, //전체 글자 줄이는 

      // 메뉴 변경을 위한 빈공간 //

      main_division:[],
      first_division: [],
      levels_division: [],
      second_division: [],

            /************* 메뉴 (언어) 고르기 ************/
      
        mainmenu_languages: [ 
          { id:0, contents:'한자', isActive:true},
          { id:1, contents:'일본어', isActive:false},
        //  { id:2, contents:'중국어', isActive:false},
        //  { id:3, contents:'산스크리트어', isActive:false},
        //  { id:4, contents:'기타 언어', isActive:false},
        //  { id:5, contents:'Spanish', isActive:false},
        ],

            /************* 대분류, 언어별 과목 구분 **************/

        division_hanja: [
          { id:0, contents:'한자능력검정시험(한국어문회)', isActive:true},
        //  { id:1, contents:'漢檢(kanken)', isActive:false},
        //  { id:2, contents:'HNK', isActive:false},
        ],

        /* 한국어, 영어, 스페인어, 중국어
        division_korean: [
          { id:0, contents:'기초', isActive:false},
          { id:1, contents:'TOKIC', isActive:false}, 
        ],

        division_English: [
          { id:0, contents:'basic', isActive:false},
          { id:1, contents:'GRE', isActive:false},
        ],

        division_spanish: [
          { id:0, contents:'base', isActive:false},
          { id:1, contents:'DELE', isActive:false},
        ],

          division_chinese: [
          { id:0, contents:'기초', isActive:false},
          { id:1, contents:'중급', isActive:false},
          { id:9, contents:'HSK', isActive:false},         
        ],

      */

        division_japanese: [
          { id:0, contents:'기초', isActive:false},
        //  { id:1, contents:'중급', isActive:false},
        //  { id:9, contents:'JLPT', isActive:false},          
        ],


       
        
        /************* 중분류, 과목별 레벨 구분 **************/

         levels_default: [
          { id:0, contents:' ', isActive:false},
         ],

          /// 한자 테스트는 이 레벨 표를 공유한다 ///
          levels_hangum:[ 
            //  {id:1, contents:'특급', isActive:false},
            //  {id:2, contents:'준특급', isActive:false},
              {id:11, contents:'1급', isActive:false},
              {id:21, contents:'2급', isActive:false},
              {id:31, contents:'3급', isActive:false},
              {id:30, contents:'준3급', isActive:false},
              {id:41, contents:'4급', isActive:false},
              {id:40, contents:'준4급', isActive:false},
              {id:51, contents: '5급', isActive:false},
              {id:50, contents: '준5급', isActive:false},
              {id:61, contents:'6급', isActive:true},
              {id:60, contents:'준6급', isActive:false},
              {id:71, contents:'7급', isActive:false},
              {id:70, contents:'준7급', isActive:false},
              {id:81, contents:'8급', isActive:false},
             
            ],

            levels_kanken: [ 
              {id:11, contents:'1級', isActive:false},
              {id:10, contents:'準1級', isActive:false},
              {id:21, contents:'2級', isActive:false},
              {id:20, contents:'準2級', isActive:false},
              {id:31, contents:'3級', isActive:false},
              {id:41, contents:'4級', isActive:false},
              {id:51, contents:'5級', isActive:false},
              {id:61, contents:'6級', isActive:false},
              {id:71, contents:'7級', isActive:false},
              {id:81, contents:'8級', isActive:false},
              {id:91, contents:'9級', isActive:false},
              {id:101, contents:'10級', isActive:false},
            ],

            /////////////////////////////////////////////////////

            levels_JLPT: [ 
              {id:11, contents:'N1', isActive:false},
              {id:21, contents:'N2', isActive:false},
              {id:31, contents:'N3', isActive:false},
              {id:41, contents:'N4', isActive:false},
              {id:51, contents:'N5', isActive:false},
            ],

///////////////////////////////////////////////////////////////////////////////////


          division_hanja_hangum: [ 

            {id:1100, contents:'낱자', isActive:true},
            {id:1101, contents:'단어', isActive:false},
            {id:1102, contents:'동음이의어', isActive:false},
          //  {id:1103, contents:'반의어', isActive:false},
            {id:1104, contents:'사자성어', isActive:false},
            {id:1105, contents:'부수', isActive:false},
            {id:1106, contents:'약자', isActive:false},
          //  {id:1107, contents:'사자성어', isActive:false},
          //  {id:1110, contents:'뜻풀이', isActive:false},
          //  {id:1111, contents:'장단음', isActive:false},

          ],

          division_japanese_basic: [
            {id:2100, contents:'히라가나', isActive:true},
            {id:2101, contents:'가타카나', isActive:false},
        //    {id:2102, contents:'단어(히라가나)', isActive:false},
        //    {id:2103, contents:'단어(가타카나)', isActive:false},
            {id:2104, contents:'일상언어', isActive:false},
          ],

////////////////////////////////////////////////////////////////////////////////////
          
          buttons: [
            {id:0, contents:'이전', isActive:false},
            {id:1, contents:'다음', isActive:false}           
          ],

        }

    },

    methods: {

      InitAtlevelChosen: function() {

        var temp1 = [];
        var temp2 = [];

        for(var i=0; i<=this.full_deck.length-1; i++) {
            if(this.full_deck[i].level == this.INITIAL_LEVEL ) {
              temp1.push(this.full_deck[i]);
            }
        } 

        temp2  = this.shuffleCards(temp1);
        return temp2;

      },

      ////////////////////////// click 관리 ////////////////////////////////

      selectContents: function(contents_id) {

        this.flushDecks();
        this.InitGetsmall();

        switch(contents_id) {
          
        // 1000: 한자, 1100: 한국 한자시험 1200: 일본 한자시험 1300: 중국 한자시험

          case 1100: // 한자능력검정시험-낱자
            this.full_deck = hangum_singleletter;
            break;

          case 1101: // 한자능력검정시험-단어
            this.full_deck = hangum_words;
            break;

          case 1102: // 한자능력검정시험-동의이의어
            this.full_deck = hangum_homonym;
            break;

          case 1104: // 한자능력검정시험-동의이의어
            this.full_deck = hangum_idioms;
            break;

          case 1105: // 한자능력검정시험-부수
            this.full_deck = hangum_radical;
            break;

          case 1106: // 한자능력검정시험-약자
            this.full_deck = hangum_abbreviation;
            break;

          ///////////////////////// 일본어 //////////////////////////

          case 2100: //히라가나
            this.full_deck = japanese_hiragana;
            break;

          case 2101: //카타카나
            this.full_deck = japanese_katakana;
            break;

          case 2104: //일본어 일상용어
            this.full_deck = japanese_daily;
            this.getSmall = true;

        }

        if(this.current_language == '한자' )
        {

          this.ready_deck = this.InitAtlevelChosen();
          this.softInit();
        
          this.cancleLevel();
          this.clickingLevel(this.INITIAL_LEVEL);
        
          this.cancleContents();
          this.clickingContents(contents_id);
          
        }

        else if(this.current_language == '일본어') {

          var temp = [];
          temp = this.full_deck;

          this.ready_deck = this.shuffleCards(temp);
          this.softInit();
              
          this.cancleContents();
          this.clickingContents(contents_id);


        }
        
      },

     
       flushDecks: function() {

         this.full_deck = [];
         this.ready_deck = [];

      },

       flushDivisions: function() {

         this.main_division = [];
         this.first_division = [];
         this.levels_division = [];
         this. second_division = [];

      },

        InitGetsmall: function() {
          
          this.getSmall = false;

      },

       cancleContents: function () {
        
        for (let key in this.second_division) {

           this.second_division[key].isActive = false;

        }
      },

      clickingContents: function(id) {

        var index = this.second_division.findIndex(item => item.id === id);

//        console.log("index는", index, "이다");
//        console.log(this.levels_division[index].isActive, "값을 다오!!!!!");

        if(this.second_division[index].isActive) {
            // 아무 것도 안함  
        }
        
        else if(!this.second_division[index].isActive) {

          this.second_division[index].isActive = true;  // <- 직접 인서트하면 에러남
               
        // console.log("클릭했으면 색깔아 바뀌어라");

        }

      },

      cancleLevel: function () {
        
        for (let key in this.levels_division) {

           this.levels_division[key].isActive = false;

        }
      },

      clickingLevel:function(id) {
      
      var index = this.levels_division.findIndex(item => item.id === id);

      if(this.levels_division[index].isActive) {
            // 아무 것도 안함
    
      }
        
      else if(!this.levels_division[index].isActive) {

       this.levels_division[index].isActive = true;  // <- 직접 인서트하면 에러남
               
      }
            
      
      },
     

      /////////////////////////////////////////////////////////////////////

      changeOtherlanguage: function(language) {

        this.offMainlnaguageActive();
        
        if (language == "한자") {

          this.main_division[0].isActive = true;
          this.current_language = language;

          this.first_division = this.division_hanja;
       
          this.spreadOtherdivisions();

          this.InitHanja();

        }


        // 일본어 초기화 함수를 만들어야 함
        else if (language == '일본어') {

          this.main_division[1].isActive = true;

          this.current_language = language;
          this.flushDecks();
          
          this.first_division = this.division_japanese;
          this.levels_division = []; //this.levels_default;
          this.second_division = this.division_japanese_basic;
        
          // 카드 갈아끼우기
          this.full_deck = japanese_hiragana;    // 한자 db에서 가져옮
          this.ready_deck = this.shuffleCards(this.full_deck);
          this.softInit();     
          
        }

      },

      spreadOtherdivisions: function() {

        if(this.current_language === '한자') {

          this.levels_division = this.levels_hangum;
          this.second_division = this.division_hanja_hangum;

        }       

        else if(this.current_language === '일본어') {

          this.first_division = this.division_japanese;
          this.levels_division = this.levels_default;
          this.second_division = this.division_japanese_basic;

         }

       },

      changeMode:function() {

        if(this.mode_readwrite == true) {
          // 외국어 읽기 모드 => 앞:외국어, 뒤:한국어

          this.mode_readwrite = false;

        }

        else {

          this.mode_readwrite = true;

        }

      },

      swapReadydeck: function() {

        var temp;        
        
       

        for(var i=0; i<=this.ready_deck.length-1; i++) {

          //상태 스왑
          temp = this.ready_deck[i].front
          this.ready_deck[i].front = this.ready_deck[i].back;
          this.ready_deck[i].back = temp;

        }

        this.changeMode();

        this.current_wordcard = this.ready_deck[0].front;

        //카드 처음으로 보내기 ->>> 나중에 함수로 바꿔라
        this.status_figures.current_turn = 0;
        this.status_figures.isFront = true;
        
      },

       dealing_card: function(item) {

                
        if(item.contents=='다음') {

          if(this.status_figures.isFront == true) {
            this.current_wordcard = this.ready_deck[this.status_figures.current_turn].back;
            this.upend_surface();
            
          }

          else if(this.status_figures.isFront == false){
            this.up_current_trun();
            this.current_wordcard = this.ready_deck[this.status_figures.current_turn].front;
            this.downend_surface();
          }
        }

        else if(item.contents=='이전') {

          if(this.status_figures.isFront == true) {
            this.down_current_trun();
            this.current_wordcard = this.ready_deck[this.status_figures.current_turn].back;
            this.upend_surface();
          }

          else if(this.status_figures.isFront == false){
            
            this.current_wordcard = this.ready_deck[this.status_figures.current_turn].front;
            this.downend_surface();
          }


        }

      },
      //////////////////////
      
      offMainlnaguageActive: function() {

        for(var i=0; i<=this.main_division.length - 1 ; i++ ) {
          this.main_division[i].isActive = false;
      
        }

      },

      //////////////////////
          
      up_current_trun: function() {

        if (this.status_figures.current_turn != this.ready_deck.length-1) {

          this.status_figures.current_turn++;
        }

        else {
              
          this.status_figures.current_turn = 0;
          this.status_figures.isFront = true;
        }
        
        
      },

      down_current_trun: function() {

        if (this.status_figures.current_turn != 0) {

          this.status_figures.current_turn--;
        }

        else {
              
          this.status_figures.current_turn = 0;
          this.status_figures.isFront = true;

        }

      },

      ///////////////////////////////////////////////////////////////////////
      // 이전 버튼 ---- 다음 버튼 //
      // 현재 카드 증가시키기 함수
      upend_surface: function() {

        this.status_figures.isFront = false;
       
      },

      downend_surface: function() {

        this.status_figures.isFront = true;
       
      },

      getTotal_deck: function() {

        this.status_figures.size_deck = this.ready_deck.length;

      },

      shuffleCards: function(decks) {
        
        var temp;
        temp = decks.sort(()=> Math.random()-0.5);
        return temp

      },

      // level을 눌렀을 때 ready_deck의 변화
      selectLevel: function(level) {

        // 레벨 딕셔너리에서 id는 레벨을 의미합니다       

        var temp_deck = [];
        this.ready_deck = [];
        
        for( var i=0; i<=this.full_deck.length-1; i++) {
            if( this.full_deck[i].level == level ) {
              temp_deck.push(this.full_deck[i]);
            }
          }

        
        this.ready_deck = this.shuffleCards(temp_deck);
        this.cancleLevel();
        this.clickingLevel(level);
        this.softInit();

        //////
                
      },

      softInit: function() {

        this.status_figures.current_turn = 0;
        this.current_wordcard = this.ready_deck[this.status_figures.current_turn].front;
        this.status_figures.isFront = true;
        this.mode_readwrite = true;
        this.getTotal_deck();

      },

      // created 되고 나서 한자 버튼을 다시 누를 때
      InitHanja: function() {

        this.flushDecks();

        // 불 다 끄고 한자에 들어오기
        this.offMainlnaguageActive();     
        this.mainmenu_languages[0].isActive = true;

        this.test = hangum_singleletter    // 한자 db에서 가져옮
        this.current_language = '한자';
        
        // 섞고 전체 덱에 넣음 - > 전체 덱이 현재 덱이 됨. 
        this.full_deck = this.shuffleCards(this.test);
        
        for(var i=0; i<=this.full_deck.length-1; i++) {
            if(this.full_deck[i].level == this.INITIAL_LEVEL ) {
              this.ready_deck.push(this.full_deck[i]);
            }
        }      


       // 현재 순서 초기화 -> 첫번째 순서의 덱에 있는 카드의 앞장을 현재 워드카드에 인서트
        this.status_figures.current_turn = 0;
        this.current_wordcard = this.ready_deck[this.status_figures.current_turn].front;
        this.status_figures.isFront = true;

        // 읽기 -> 쓰기 = 모드온
        this.mode_readwrite = true;
        this.getTotal_deck(); //전체 덱크기를 측정해서 appStatus에 사용함

        // 장르와 세부 과목 다시 인서트
        this.flushDivisions();
        this.InitGetsmall();
        this.main_division = this.mainmenu_languages;
        this.first_division = this.division_hanja;
        
        this.levels_division = this.levels_hangum;
        this.second_division = this.division_hanja_hangum;   
    
    }      
      
},
     
      created: function() {
        // this.ready_deck = this.test.sort(()=> Math.random()-0.5 );
        console.log("시작합니다");
        this.test = hangum_singleletter    // 한자 db에서 가져옮
        this.current_language = '한자';
        /////////////////////// 여기서는 이렇게 해도 앞으로는 함수를 사용해야 함  ///////////////////////

        // 섞고 전체 덱에 넣음 - > 전체 덱이 현재 덱이 됨. 
        this.full_deck = this.shuffleCards(this.test);
        
        for(var i=0; i<=this.full_deck.length-1; i++) {
            if(this.full_deck[i].level == this.INITIAL_LEVEL ) {
              this.ready_deck.push(this.full_deck[i]);
            }
        }      

        // 현재 순서 초기화 -> 첫번째 순서의 덱에 있는 카드의 앞장을 현재 워드카드에 인서트
        this.status_figures.current_turn = 0;
        this.current_wordcard = this.ready_deck[this.status_figures.current_turn].front;
        this.status_figures.isFront = true;

        // 읽기 -> 쓰기 = 모드온
        this.mode_readwrite = true;
        this.getTotal_deck(); //전체 덱크기를 측정해서 appStatus에 사용함

        
        this.main_division = this.mainmenu_languages;
        this.first_division = this.division_hanja;
        this.levels_division = this.levels_hangum;
        this.second_division = this.division_hanja_hangum;

    }
}
</script>


<style>

#app {

width: 1200px;

margin-left: auto;
margin-right: auto;
box-sizing: border-box;

font-family: 'Noto Sans KR', sans-serif;

}

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&family=Noto+Serif+TC:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dongle&family=Marcellus+SC&family=Noto+Serif+KR:wght@200;700&family=Song+Myung&display=swap');

.header {

 display:flex;
 justify-content: space-between;
 align-items: center;

 top: 0;

 width: 1200px;
 height: 70px;

 margin-left: auto;
 margin-right: auto;   

 background-color: white;


}


.logo {
  
  box-sizing: border-box;
  display:inline-block;

 text-align: center;

  width: 290px;
  height: 60px;

  margin-right: 5px;  

  font-family: 'Marcellus SC', serif;

   
}


.maingenre {
  
  box-sizing: border-box;
  
  display:inline-block;
  
  text-align: center;
  width: 700px;
  height: 60px;

  /*background-color: orangered;*/
  /*  margin-left: 10px; */
  margin-right: 10px;
  
}


.advertisement {
  box-sizing: border-box;

  display:inline-block;
  
  text-align: center;
  width: 180px;  
  height: 60px;

  border: solid 2px;

  /*background-color: rebeccapurple;*/
     
}

/* 사용 안함

.genreblock {
  background-color: grey;

  box-sizing: border-box;
  
  display:inline-block;
  
  text-align: center;

  width: 100px;
  height: 50px;
  margin-left: 10px; 
  margin-right: 10px; 

}*/

.neck {

  display:flex;
  justify-content: space-between;

  margin-top: 5px;

}


.body {

  width: auto;


}

.divisions {

  display:block;

}

.division1 {
  
    display:inline-block;

    width: 1000px;
    height: 50px; 

    margin-bottom: 5px;

    border: solid 1px;

    /* background-color: #534949; */
  
}

.division2 {

    width: 1000px;
    height: 50px;

    margin-bottom: 5px;

    border: solid 1px;

     /* background-color: rgb(97, 50, 50); */

}

.division3 {

    width: 1000px;
    height: 50px;

    margin-bottom: 5px;

    border: solid 1px;

    /* background-color: #9922FF; */
}

.mode {

  display:flex;
  align-items: center; /* 자식노드 세로 정렬*/
  
  width: 172px;
  height:165px;

  margin-left: 5px;
  margin-right: 5px;

  /* border: solid 2px; */
/* background-color: blue; */

}



.window {


  box-sizing: border-box;
  display:inline-block;

  text-size-adjust: auto;
  
  width: 1200px;
  height: 515px;

  border: solid 2px;
  border-color:#797979;

  margin-bottom: 5px;

   /* font-family: 'Noto Serif TC', serif; */
  
}

.status {

  width: 1200px;
  height: 60px;

  margin-bottom: 5px;

  border: solid 1px;
  border-color:#797979

   /* background-color: #5982aa; */

}


.button {


  display: flex;
  justify-content: center;  

  width: 1200px;
  height: 90px;

  padding-top:10px;

  margin-bottom: 5px;
  
 /* border: solid 2px; */

  /* background-color: rgb(211, 175, 175); */

}


.footer {

  width: 1200px;
  height: 50px;

  border-top: 1px solid #444444;

/*  background-color: rgb(137, 192, 137); */
  
}

</style>


