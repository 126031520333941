<template>
    <div>
        
        <div
            v-on:click="spreadLevelDivisions(item)" 
            v-for="item in passed_level_divisions" v-bind:key="item.id" v-bind:class="[{'levelBtnActive': item.isActive}, { 'levelBtnOff': item.contents === ' '}]"  class="levelBtn">
            {{ item.contents }}
        </div>

    </div>
</template>
  
  <script>
      export default {

        props: ['passed_level_divisions'],

        methods: {

            spreadLevelDivisions: function(item) {
                                                
                this.$emit('clicked_leveldivision', item.id);
                
            }

        } 

    }
  </script>
  
  <style>
    .levelBtn {

        box-sizing: border-box;

        display:inline-flex;
        justify-content: center;

        align-items: center;

        height: 40px;
        width: 55px;


        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;

        padding-top: 5px;
        padding-bottom: 10px;
        border-radius: 5px;

        font-size: 16px;

        background-color: #f1f0f0; 
        cursor: pointer;
        

    }

    .levelBtnActive {

        background-color: #bbb7b7; 
        cursor: pointer;

    }

    .levelBtnOff {

        pointer-events: none;

    }
  </style>